import React, { useState, useEffect } from "react";
import "./Keyboard.css";

import { CapsLock, Space, Delete, Clear, Prev, Next, Enter } from "./SVG";

let upperCase = false;

const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "!",
  ",",
  ".",
  "@",
];
const numbers = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "/",
  "#",
  "+",
  '"',
  "$",
  "%",
  "&",
  "'",
  "(",
  ")",
  "*",
  "_",
  "-",
  "~",
];

/* eslint-disable react/prop-types */
const TVKeyboard = (props) => {
  const { numbers: isNumbers, value, handler, close: onClose, colors, logo, text } = props;
  const [isLetters, setIsLetters] = useState(!isNumbers);
  // POSITION OF MOVER
  const [position, setPosition] = useState(0);
  const [writePosition, setWritePosition] = useState(value.length);
  // KEYS
  const [keys, setKeys] = useState(isNumbers ? numbers : letters);

  const addToText = (letter) => {
    setWritePosition((p) => p + 1);
    handler(
      value.slice(0, writePosition) +
      letter +
      value.slice(writePosition)
    );
  };

  const close = () => {
    setTimeout(() => {
      onClose();
    }, 1);
  };

  const numberHandler = () => {
    if (isLetters) {
      setKeys(numbers);
    } else {
      setKeys(letters);
    }
    setIsLetters((p) => !p);
  };

  const transformKeys = () => {
    if (isLetters) {
      const updatedKeys = keys.map((key) =>
        upperCase ? key.toLowerCase() : key.toUpperCase()
      );
      upperCase = !upperCase;
      setKeys(updatedKeys);
    }
  };

  const deleteFromText = () => {
    if (writePosition > 0) {
      setWritePosition((p) => p - 1);
    }

    handler(
      value.slice(0, writePosition).slice(0, -1) +
      value.slice(writePosition)
    );
  };

  const clearText = () => {
    setWritePosition(0);
    handler("");
  };

  const actions = (code) => {
    if (code === 0) {
      transformKeys();
    } else if (code === 1) {
      numberHandler();
    } else if (code === 2) {
      addToText(`\xa0`);
    } else if (code === 3) {
      deleteFromText();
    } else if (code === 4) {
      clearText();
    } else if (code === 5 && writePosition > 0) {
      setWritePosition((p) => p - 1);
    } else if (code === 6 && writePosition < value.length) {
      setWritePosition((p) => p + 1);
    }
  };

  useEffect(() => {
    const mover = (e) => {
      // e.preventDefault();
      // e.stopPropagation();
      switch (e.keyCode) {
        // RIGHT ARROW
        case 39:
          console.log(position);
          if (position === 100) {
            if (writePosition < value.length) {
              setWritePosition((p) => p + 1);
            }
          } else if (position === 8 || position === 17 || position === 26) {
            setPosition((p) => p - 8);
          } else if (position === 29) {
            if (isLetters) {
              setPosition(30)
            } else {
              setPosition(31);
            }
          } else if (position === 32) {
            setPosition(27);
          } else if (position === 35) {
            setPosition(33);
          } else if (position === 37) {
            setPosition(36);
          } else if (position < 37) {
            setPosition((p) => p + 1);
          }
          break;
        // LEFT ARROW
        case 37:
          if (position === 100) {
            if (writePosition > 0) {
              setWritePosition((p) => p - 1);
            }
          } else if (position % 9 === 0 && position < 21) {
            setPosition((p) => p + 8);
          } else if (position === 27) {
            setPosition(32);
          } else if (position === 33) {
            setPosition(35);
          } else if (position === 36) {
            setPosition(37);
          } else if (position === 31 && !isLetters) {
            setPosition(29);
          } else if (position > 0) {
            setPosition((p) => p - 1);
          }
          break;
        // DOWN ARROW
        case 40:
          if (position < 21) {
            setPosition((p) => p + 9);
          } else if (position < 23) {
            if (isLetters) {
              setPosition(30)
            } else {
              setPosition(31);
            }
          } else if (position === 23) {
            setPosition(31);
          } else if (position < 27) {
            setPosition(32);
          } else if (position < 29) {
            setPosition(33);
          } else if (position < 32) {
            setPosition(34);
          } else if (position < 33) {
            setPosition(35);
          } else if (position < 34) {
            setPosition(36);
          } else if (position < 36) {
            setPosition(37);
          } else if (position === 100) {
            setPosition(0);
          }
          break;
        // UP ARROW
        case 38:
          if (position === 37) {
            setPosition(35);
          } else if (position === 36) {
            setPosition(33);
          } else if (position === 35) {
            setPosition(32);
          } else if (position === 34) {
            if (isLetters) {
              setPosition(30)
            } else {
              setPosition(31);
            }
          } else if (position === 33) {
            setPosition(27);
          } else if (position === 32) {
            setPosition(25);
          } else if (position === 31) {
            setPosition(23);
          } else if (position > 8 && position !== 100) {
            setPosition((p) => p - 9);
          } else {
            setPosition(100);
          } break;
        // ENTER
        case 13:
          if (position < 30) {
            addToText(keys[position]);
          }
          actions(position - 30);
          if (position === 37) {
            close();
          } break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", mover);

    return () => window.removeEventListener("keydown", mover);
  });

  // MAPING KEYS TO KEYPADS
  const keypads = keys.map((key, index) => (
    <div
      key={key}
      style={{
        background: position === index ? colors[2] : colors[1],
        fontSize: "25px",
      }}
      onMouseOver={() => setPosition(index)}
      onFocus={() => setPosition(index)}
      onClick={() => addToText(key)}
      onKeyDown={(ev) => {
        if (ev.keyCode === 13) {
          addToText(key)
        }
      }}
      className="key"
      role="button"
      tabIndex={0}
    >
      {key}
    </div>
  ));

  const getBackgroundColor = () => {
    if (position === 30) {
      return colors[2];
    }

    if (isLetters) {
      return `${colors[1]}bb`;
    }

    return `${colors[1]}33`;
  }

  return (
    <div className="TVKeyboard">
      <div className="overlay" />
      <div className="keyboard" style={{ background: colors[0] }}>
        {logo ? <div className="header" style={{ background: colors[1] }}>
          <img src={logo} alt="TV Keyboard" />
        </div> : null}

        <div className="container">
          <div className="input">
            <p>{text}</p>
            <div
              className="value"
              style={{
                borderColor:
                  position === 100 ? colors[2] : colors[1],

              }}
            >
              <span>{value.slice(0, writePosition)}</span>
              <div className="show">|</div>
              <span>{value.slice(writePosition)}</span>
            </div>
          </div>

          <div className="keypads">
            {keypads}
            <div
              key={0}
              style={{
                background: getBackgroundColor(),
              }}
              onClick={() => actions(0)}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  actions(0)
                }
              }}
              onMouseOver={() => isLetters && setPosition(30)}
              onFocus={() => isLetters && setPosition(30)}
              className="key CAPS"
              role="button"
              tabIndex={0}
            >
              <CapsLock />
              CAPS
            </div>
            <div
              key={1}
              style={{
                background:
                  position === 31 ? colors[2] : `${colors[1]}bb`,
              }}
              onClick={() => actions(1)}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  actions(1)
                }
              }}
              onMouseOver={() => setPosition(31)}
              className="key"
              onFocus={() => setPosition(31)}
              role="button"
              tabIndex={0}
            >
              {isLetters ? "123" : "abc"}
            </div>
            <div
              key={2}
              style={{
                background:
                  position === 32 ? colors[2] : `${colors[1]}bb`,
              }}
              onClick={() => actions(2)}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  actions(2)
                }
              }}
              onMouseOver={() => setPosition(32)}
              className="key SPACE"
              onFocus={() => setPosition(32)}
              role="button"
              tabIndex={0}
            >
              <Space />
              SPACE
            </div>
            <div
              key={3}
              style={{
                background:
                  position === 33 ? colors[2] : `${colors[1]}bb`,
              }}
              onClick={() => actions(3)}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  actions(3)
                }
              }}
              onMouseOver={() => setPosition(33)}
              className="key DELETE"
              onFocus={() => setPosition(33)}
              role="button"
              tabIndex={0}
            >
              <Delete />
              DELETE
            </div>
            <div
              key={4}
              style={{
                background:
                  position === 34 ? colors[2] : `${colors[1]}bb`,
              }}
              onClick={() => actions(4)}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  actions(4)
                }
              }}
              onMouseOver={() => setPosition(34)}
              className="key CLEAR"
              onFocus={() => setPosition(34)}
              role="button"
              tabIndex={0}
            >
              <Clear />
              CLEAR
            </div>
            <div
              key={5}
              style={{
                background:
                  position === 35 ? colors[2] : `${colors[1]}bb`,
              }}
              onClick={() => actions(5)}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  actions(5)
                }
              }}
              onMouseOver={() => setPosition(35)}
              className="key PREV"
              onFocus={() => setPosition(35)}
              role="button"
              tabIndex={0}
            >
              <Prev />
              PREV
            </div>
            <div
              key={6}
              style={{
                background:
                  position === 36 ? colors[2] : `${colors[1]}bb`,
              }}
              onClick={() => actions(6)}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  actions(6)
                }
              }}
              onMouseOver={() => setPosition(36)}
              className="key NEXT"
              onFocus={() => setPosition(36)}
              role="button"
              tabIndex={0}
            >
              NEXT
              <Next />
            </div>
            <div
              className="key ENTER"
              style={{
                background:
                  position === 37 ? colors[2] : `${colors[1]}66`,
              }}
              onMouseOver={() => setPosition(37)}
              onClick={() => close()}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  close()
                }
              }}
              onFocus={() => setPosition(37)}
              role="button"
              tabIndex={0}
            >
              ENTER
              <Enter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TVKeyboard;
