/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import OneStudioSSAIAdsPlugin from '../../onestudio-ssai-ads/src/onestudio-ads';
import play from '../../assets/images/icons/play2.png';
import pause from '../../assets/images/icons/pause.png';
import fastforward from '../../assets/images/icons/fast-forward.png';
import rewind from '../../assets/images/icons/rewind.png';
import closedCaptionIcon from '../../assets/images/icons/caption_icon.png';
import { setUserVideoProgress } from '../../utils/localCache.util';
import getIp from '../../services/ip.service';
import closeIcon from '../../assets/images/icons/close.png';
import 'video.js/dist/video-js.css';
import '../../onestudio-ssai-ads/onestudioadsplayer/css/onestudio-ads.css';
import useDeviceInfo from '../../hooks/useDeviceInfo';
import voiceSpeak from '../../utils/accessibility.util';

const Player = ({ id, videoData, resumeFrom, handlePlayerClose }) => {
  const videoPlayerContainer = useRef();
  const playerIns = useRef();
  const timeoutRef = useRef();
  const {
    registerCallback,
    getDeviceHeight,
    getDeviceWidth,
    getUUID,
    getUserAgent,
    ifa,
    ifaType,
  } = useDeviceInfo();

  const playerObj = {
    played: false,
    videoUrl: '',
    vastUrl: '',
    vastCallType: 'Direct', // or 'APS'
    ip: '',
  };

  const isLive = false;
  const appstoreUrl = process.env.REACT_APP_VIZIO_APPSTORE_URL;
  const appName = process.env.REACT_APP_VIZIO_APP_NAME;
  const appBundleId = process.env.REACT_APP_VIZIO_APP_BUNDLE;
  const appChannelName = process.env.REACT_APP_VIZIO_CHANNEL_NAME;
  const contentSeries = '';
  const videoId = videoData.id;
  const { title, season, episode, rating, channelId, duration } = videoData;
  const genres = videoData.genres || 'tvmovies';
  const liveStream = '';
  const producerName = '';
  const randomCB = Math.floor(Math.random() * 90000) + 10000;

  const [isPlaying, setIsPlaying] = useState(false);
  const [caption, setCaptions] = useState(null);
  const [selectedCap, setSelectedCap] = useState('');

  useEffect(() => {
    registerCallback();
  }, []);

  const timeFormat = (seconds) => {
    let secs = seconds;

    if (!secs && secs <= 0) {
      return '00:00:00';
    }

    let hours = null;
    if (secs / 3600 > 0) {
      hours = Math.floor(secs / 3600);
      secs -= hours * 3600;
    } else {
      hours = 0;
    }

    let mins = null;
    if (secs / 60 > 0) {
      mins = Math.floor(secs / 60);
      secs -= mins * 60;
    } else {
      mins = 0;
    }

    secs = Math.floor(secs);

    const hoursString = hours >= 10 ? String(hours) : `0${String(hours)}`;
    const minsString = mins >= 10 ? String(mins) : `0${String(mins)}`;
    const remainderString = secs >= 10 ? String(secs) : `0${String(secs)}`;

    return `${hoursString}:${minsString}:${remainderString}`;
  };

  const updateProgressBar = (current, total) =>
    (Math.floor(current) / Math.floor(total)) * 100;

  const initMediaMelon = () => {
    const mmvjs7Plugin = new window.VideoJSMMSSIntgr();
    if (mmvjs7Plugin.getRegistrationStatus() === false) {
      mmvjs7Plugin.registerMMSmartStreaming('OTT Studio', '165163458');
      mmvjs7Plugin.reportPlayerInfo(
        '$PlayerBrand',
        '$PlayerModel',
        '$PlayerVersion'
      );
      mmvjs7Plugin.reportPlayerInfo('videojs-vhs', 'vhs', '7');
      mmvjs7Plugin.reportAppInfo('FreeMoviesPlus', '1.1.0');
      mmvjs7Plugin.setDeviceInfo('Fire TV');
    }

    const mmVideoAssetInfo = {
      assetName: title,
      assetId: channelId,
      videoId,
      contentType: isLive,
      genre: genres,
      title,
      drmProtection: 'none',
      episodeNumber: episode,
      season,
      seriesTitle: contentSeries,
      videoType: isLive ? 'LIVE' : 'VOD',
    };

    mmvjs7Plugin.initialize(
      playerIns.current,
      playerObj.videoUrl,
      mmVideoAssetInfo,
      null
    );
  };

  const handlePlayerControlDisplay = (showHide = '') => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (showHide === '') {
      if (window.document.getElementById('player-bottom-bar')) {
        window.document.getElementById('player-bottom-bar').style.display =
          'block';
      }
      timeoutRef.current = setTimeout(() => {
        if (window.document.getElementById('player-bottom-bar')) {
          window.document.getElementById('player-bottom-bar').style.display =
            'none';
        }
        if (window.document.getElementById('caption-container')) {
          window.document
            .getElementById('caption-container')
            .classList.remove('show');
          window.document
            .getElementById('caption-container')
            .classList.add('hide');
        }
      }, 7000);
    } else if (window.document.getElementById('player-bottom-bar')) {
      window.document.getElementById('player-bottom-bar').style.display =
        showHide;
    }
  };

  const handlePlayerPlayPause = () => {
    if (playerIns.current.paused()) {
      playerIns.current.play();
    } else {
      playerIns.current.pause();
    }
  };

  const handlePlayerForward = () => {
    playerIns.current.currentTime(playerIns.current.currentTime() + 10);
  };

  const handlePlayerBackward = () => {
    playerIns.current.currentTime(playerIns.current.currentTime() - 10);
  };

  const setupPlayer = () => {
    try {
      if (playerIns.current) return;

      const onestudioPluginOptions = {
        type: 'vast',
        midrollInterval: 7,
        apsURL: playerObj.vastUrl,
        url: playerObj.vastUrl,
        debug: true,
        adMarkers: true,
      };
      if (playerObj.vastCallType === 'APS') {
        delete onestudioPluginOptions.url;
      } else {
        delete onestudioPluginOptions.apsURL;
      }

      videojs.registerPlugin('onestudioads', OneStudioSSAIAdsPlugin);
      playerIns.current = videojs(videoPlayerContainer.current, {
        controls: true,
        autoplay: true,
        fluid: true,
        preload: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        html5: {
          vhs: {
            experimentalBufferBasedABR: true,
          },
        },
        plugins: {
          onestudioads: onestudioPluginOptions,
        },
        poster: videoData.poster,
        sources: [
          {
            type: 'application/x-mpegurl',
            src: playerObj.videoUrl,
          },
        ],
        tracks: [
          {
            src: videoData.vttUrl,
            kind: 'subtitles',
            srclang: 'en',
            label: 'English',
          },
        ],
      });

      initMediaMelon();

      playerIns.current.on('loadedmetadata', () => {
        const tracks = playerIns.current.remoteTextTracks();
        const subtitleTracks = [
          {
            id: '',
            kind: '',
            label: 'Off',
            language: '',
          },
        ];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tracks.length; i += 1) {
          const track = tracks[i];
          if (track.kind === 'subtitles') {
            const tmp = {
              id: track.id,
              kind: track.kind,
              label: track.label,
              language: track.language,
              mode: track.mode,
              src: track.src,
            };
            subtitleTracks.push(tmp);
          }
        }

        setCaptions(subtitleTracks);
      });

      playerIns.current.on('adstart', (ad) => {
        handlePlayerControlDisplay('none');
      });
      playerIns.current.on('ads-ad-ended', () => {
        handlePlayerControlDisplay();
      });
      playerIns.current.on('adscanceled', () => {
        handlePlayerControlDisplay();
      });

      playerIns.current.on('play', () => {
        handlePlayerControlDisplay();
        setIsPlaying(false);
        if (resumeFrom > 0 && !playerObj.played) {
          playerObj.played = true;
          playerIns.current.currentTime(resumeFrom);
        }
      });

      playerIns.current.on('timeupdate', () => {
        const watchTime = Number(Math.floor(playerIns.current.currentTime()));
        if (watchTime === 0) return;

        if (watchTime % 5 === 0) {
          setUserVideoProgress(videoData.id, watchTime);
        }

        const currentDuration = timeFormat(watchTime);
        if (window.document.getElementById('current-time')) {
          window.document.getElementById('current-time').innerText =
            currentDuration;
        }

        // Update ProgressBar
        if (watchTime % 2 === 0) {
          if (window.document.getElementById('player-progress')) {
            window.document.getElementById(
              'player-progress'
            ).style.width = `${updateProgressBar(
              watchTime,
              videoData.duration
            )}%`;
          }
        }
      });

      playerIns.current.on('pause', () => {
        handlePlayerControlDisplay('display');
        setIsPlaying(true);
        const watchTime = Math.floor(playerIns.current.currentTime());
        if (watchTime === 0) return;

        setUserVideoProgress(videoData.id, Number(watchTime));
        handlePlayerControlDisplay('display');
      });

      playerIns.current.on('seeked', () => {
        handlePlayerControlDisplay();
      });

      playerIns.current.on('ended', () => {
        setIsPlaying(false);
        handlePlayerClose();
      });

      playerIns.current.on('keyPressShowControls', () => {
        handlePlayerControlDisplay();
      });

      // Set total duration
      const totalDuration = timeFormat(Math.floor(videoData.duration));
      window.document.getElementById('total-time').innerText = totalDuration;
    } catch (e) {
      // ignore
    }
  };

  const prepareVastWithAps = () => {
    let vastParams = process.env.REACT_APP_VAST_PARAMS;
    vastParams = vastParams.replace('{content_type}', '');
    vastParams = vastParams.replace('{slot_type}', '');
    vastParams = vastParams.replace(
      '{device_ifa}',
      encodeURIComponent(getUUID())
    );
    vastParams = vastParams.replace('{ua}', encodeURIComponent(getUserAgent()));
    vastParams = vastParams.replace('{video_id}', videoId);
    vastParams = vastParams.replace(
      '{content_genre}',
      encodeURIComponent(genres)
    );
    vastParams = vastParams.replace(
      '{video_rating}',
      encodeURIComponent(rating)
    );
    vastParams = vastParams.replace('{content_duration}', duration);
    vastParams = vastParams.replace('{us_privacy}', '');
    vastParams = vastParams.replace('{device_height}', getDeviceHeight());
    vastParams = vastParams.replace('{device_width}', getDeviceWidth());
    vastParams = vastParams.replace('{player_height}', getDeviceHeight());
    vastParams = vastParams.replace('{player_width}', getDeviceWidth());
    vastParams = vastParams.replace('{dnt}', '');
    vastParams = vastParams.replace('{language}', 'en');
    vastParams = vastParams.replace('{connection_type}', '');
    vastParams = vastParams.replace('{category}', '');
    vastParams = vastParams.replace('{cb}', randomCB);
    vastParams = vastParams.replace('{content_episode}', episode);
    vastParams = vastParams.replace('{media_title}', encodeURIComponent(title));
    vastParams = vastParams.replace(
      '{series_title}',
      encodeURIComponent(contentSeries)
    );
    vastParams = vastParams.replace('{content_season}', season);
    vastParams += `&app_bundle=${appBundleId}`;
    vastParams += `&app_name=${encodeURIComponent(appChannelName)}`;
    vastParams += `&app_store_url=${encodeURIComponent(appstoreUrl)}`;

    playerObj.videoUrl = videoData.hlsUrl;
    playerObj.vastUrl = `${process.env.REACT_APP_VAST_BASE_URL}?${vastParams}`;
  };

  const prepareDirectVast = () => {
    let vastURL = process.env.REACT_APP_DIRECT_VAST_URL;
    vastURL += '?w=1920&h=1080';
    vastURL += `&cb=${randomCB}`;
    vastURL += `&ip=${encodeURIComponent(playerObj.ip)}`;
    vastURL += `&ua=${encodeURIComponent(getUserAgent())}`;
    vastURL += '&pod_max_dur=150';
    vastURL += `&app_bundle=${appBundleId}`;
    vastURL += `&app_name=${encodeURIComponent(appName)}`;
    vastURL += `&app_store_url=${encodeURIComponent(appstoreUrl)}`;
    vastURL += `&content_id=${videoId}`;
    vastURL += `&content_episode=${episode}`;
    vastURL += `&content_title=${encodeURIComponent(title)}`;
    vastURL += `&content_series=${encodeURIComponent(contentSeries)}`;
    vastURL += `&content_season=${season}`;
    vastURL += `&content_genre=${encodeURIComponent(genres)}`;
    vastURL += `&content_livestream=${encodeURIComponent(liveStream)}`;
    vastURL += `&content_producer_name=${encodeURIComponent(producerName)}`;
    vastURL += `&rating=${encodeURIComponent(rating)}`;
    vastURL += `&channel_name=${encodeURIComponent(appChannelName)}`;
    vastURL += '&language=en&network_name=';
    vastURL += `&did=${encodeURIComponent(getUUID())}`;
    vastURL += '&gdpr=0&us_privacy=1---&schain=&lmt=&ic=IAB1-5&max_dur=150';

    playerObj.videoUrl = videoData.hlsUrl;
    // playerObj.vastUrl = vastURL;
  };

  useEffect(() => {
    if (playerObj.vastCallType === 'APS') {
      prepareVastWithAps();
      setupPlayer();
    } else {
      getIp().then((ip) => {
        playerObj.ip = ip;
        prepareDirectVast();
        setupPlayer();
      });
    }

    return () => {
      if (playerIns.current) {
        setTimeout(() => {
          playerIns.current.dispose();
        }, 2000);
      }
    };
  }, []);

  const changeCaption = (captionId) => {
    const newCaption = caption;
    setSelectedCap(captionId);
    // eslint-disable-next-line no-return-assign
    newCaption.map((a, i) => (caption[i].mode = 'disabled'));
    const selectedTrack = newCaption.findIndex((c) => c.id === captionId);
    if (selectedTrack > -1) {
      if (newCaption[selectedTrack].id) {
        newCaption[selectedTrack].mode = 'showing';
        playerIns.current.addRemoteTextTrack(newCaption[selectedTrack]);
      } else {
        const tt = playerIns.current.remoteTextTracks();
        for (let i = 0; i < tt.length; i += 1) {
          const track = tt[i];
          if (track.kind === 'subtitles' && track.mode === 'showing') {
            track.mode = 'disabled';
            playerIns.current.addRemoteTextTrack(track);
          }
        }
      }
    }
    document.getElementById('caption-container').classList.remove('show');
    document.getElementById('caption-container').classList.add('hide');
    document
      .getElementById(`caption-${selectedTrack}`)
      .classList.remove('focused');
    document.getElementById('caption-btn').classList.add('focused');
    handlePlayerControlDisplay();
  };

  const openCaptionDialog = () => {
    handlePlayerControlDisplay('block');
    document.getElementById('caption-container').classList.remove('hide');
    document.getElementById('caption-container').classList.add('show');
    document.getElementById('caption-btn').classList.remove('focused');
    document.getElementById('caption-0').classList.add('focused');
  };

  const handleOnButtonFocus = useCallback((text) => () => {
    voiceSpeak(text);
  }, []);

  return (
    <div className="video-container" id="video-container">
      <div id="video-player">
        <video
          id={id}
          className="video-js vjs-default-skin vjs-big-play-centered"
          width="auto"
          height="auto"
          ref={videoPlayerContainer}
        />
      </div>

      <div className="player-overlay">
        <div
          className="player-bottom-bar"
          id="player-bottom-bar"
          style={{ display: 'none' }}
        >
          <div className="player-text">
            <div className="player-watching-text">You&#39;re Watching</div>
            <div className="player-title">{title}</div>
          </div>
          <div className="player-progress-track">
            <div id="player-progress" className="player-progress-bar" />
          </div>
          <div className="player-timer">
            <div id="current-time" className="player-time">
              00:00:00
            </div>
            /
            <div id="total-time" className="player-time">
              00:00:00
            </div>
            <div className="player-button-group">
              <div
                className="rewind media-btn prj-element"
                id="rewind"
                data-focus-left="#rewind"
                data-focus-right="#play-pause"
                data-focus-up="#rewind"
                data-focus-down="#rewind"
                onClick={handlePlayerBackward}
                tabIndex={-1}
                role="none"
                onFocus={handleOnButtonFocus("Rewind button")}
              >
                <img src={rewind} alt="" className="media-btn-img" />
              </div>
              <div
                className="playpause media-btn prj-element focused active"
                id="play-pause"
                data-focus-left="#rewind"
                data-focus-right="#fast-forward"
                data-focus-up="#play-pause"
                data-focus-down="#play-pause"
                tabIndex={-1}
                role="none"
                onClick={handlePlayerPlayPause}
                onFocus={handleOnButtonFocus(isPlaying ? "Play button" : "Pause button")}
              >
                <img
                  src={isPlaying ? play : pause}
                  alt="play-pause-img"
                  className="media-btn-img"
                />
              </div>
              <div
                className="fastforward media-btn prj-element"
                id="fast-forward"
                data-focus-left="#play-pause"
                data-focus-right={
                  videoData.vttUrl ? '#caption-btn' : '#fast-forward'
                }
                data-focus-up="#fast-forward"
                data-focus-down="#fast-forward"
                onClick={handlePlayerForward}
                tabIndex={-1}
                role="none"
                onFocus={handleOnButtonFocus("Fast forward button")}
              >
                <img src={fastforward} alt="" className="media-btn-img" />
              </div>
              <div>
                <div id="caption-container" className="caption-container hide">
                  <div className="caption-section">
                    {caption &&
                      caption.length > 0 &&
                      caption.map((c, idx) => (
                        <div
                          id={`caption-${idx}`}
                          key={`caption-${c.id}`}
                          data-focus-left="#fast-forward"
                          data-focus-right={false}
                          data-focus-up={
                            idx === 0 ? false : `#caption-${idx - 1}`
                          }
                          data-focus-down={
                            idx + 1 === caption.length
                              ? false
                              : `#caption-${idx + 1}`
                          }
                          className={
                            c.id === selectedCap
                              ? 'caption-item caption-item-selected prj-element media-btn'
                              : 'caption-item prj-element media-btn'
                          }
                          onClick={() => changeCaption(c.id)}
                          tabIndex={-1}
                          role="none"
                          onFocus={handleOnButtonFocus(c.label)}

                        >
                          {c.label}
                        </div>
                      ))}
                  </div>
                </div>
                {videoData.vttUrl && (
                  <div
                    className="fastforward media-btn prj-element closed-caption"
                    id="caption-btn"
                    data-focus-left="#fast-forward"
                    data-focus-right={false}
                    data-focus-up={false}
                    data-focus-down={false}
                    onClick={openCaptionDialog}
                    tabIndex={-1}
                    role="none"
                    onFocus={handleOnButtonFocus("Closed Caption button")}
                  >
                    <img
                      src={closedCaptionIcon}
                      alt=""
                      className="media-btn-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="close-video-container prj-element"
        id="back-to-page-video"
        onClick={() => handlePlayerClose()}
      >
        <img src={closeIcon} alt="close" />
      </button>
    </div>
  );
};

Player.propTypes = {
  id: PropTypes.string.isRequired,
  videoData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    poster: PropTypes.string,
    posterH: PropTypes.string,
    posterV: PropTypes.string,
    hlsUrl: PropTypes.string,
    isPortrait: PropTypes.bool,
    genres: PropTypes.string,
    duration: PropTypes.number,
    category: PropTypes.string,
    channelId: PropTypes.number,
    director: PropTypes.string,
    actor1: PropTypes.string,
    actor2: PropTypes.string,
    actor3: PropTypes.string,
    rating: PropTypes.string,
    ratingSource: PropTypes.string,
    season: PropTypes.number,
    episode: PropTypes.number,
    srtUrl: PropTypes.string,
    vttUrl: PropTypes.string,
    source: PropTypes.string,
    playDirectUrl: PropTypes.string,
    liveVastUrl: PropTypes.string,
  }).isRequired,
  resumeFrom: PropTypes.number.isRequired,
  handlePlayerClose: PropTypes.func.isRequired,
};

export default Player;
