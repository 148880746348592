import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useDeviceInfo = () => {
  const [deviceId, setDeviceId] = useState('');
  const [ifa, setIFA] = useState('');
  const [ifaType, setIFAType] = useState('');
  const [lmt, setLMT] = useState('');
  const [isTTSEnabled, setIsTTSEnabled] = useState(false);
  const [isCCEnabled, setIsCCEnabled] = useState(false);
  const [firmwareVersion, setFirmwareVersion] = useState('');

  const addCallback = () => {
    // all secondary handlers inside the primary
    // Device ID
    window.VIZIO.getDeviceId((id) => {
      console.log(`Unique Device Id: ${id}`);
      setDeviceId(id);
    });
    // IFA - ID for Advertisement
    window.VIZIO.setAdvertiserIDListener((AdvertiserID) => {
      console.log(`Advertiser ID: ${AdvertiserID.IFA}`);
      console.log(`Advertiser ID Type: ${AdvertiserID.IFA_TYPE}`);
      console.log(`Limit Ad Tracking: ${AdvertiserID.LMT}`);
      setIFA(AdvertiserID.IFA);
      setIFAType(AdvertiserID.IFA_TYPE);
      setLMT(AdvertiserID.LMT);
    });
    // TTS
    document.addEventListener(
      'VIZIO_TTS_ENABLED',
      (e) => {
        setIsTTSEnabled(true);
        console.log(e, 'TTS ENABLED');
      },
      false
    );
    document.addEventListener(
      'VIZIO_TTS_DISABLED',
      (e) => {
        setIsTTSEnabled(false);
        console.log(e, 'TTS DISABLED');
      },
      false
    );
    // Closed Captions
    window.VIZIO.setClosedCaptionHandler((ccEnabled) => {
      setIsCCEnabled(ccEnabled);
      if (ccEnabled) {
        console.log('Closed captioning enabled');
      } else {
        console.log('Closed captioning disabled');
      }
    });
    window.VIZIO.getFirmwareVersion((version) => {
      console.log(`Device Firmware Version: ${version}`);
      setFirmwareVersion(version);
    });
  };

  const registerCallback = () => {
    if (window.VIZIO) {
      addCallback();
    } else {
      document.addEventListener('VIZIO_LIBRARY_DID_LOAD', addCallback, false);
    }
  };

  const getUserAgent = () => window.navigator.userAgent;

  const getDeviceModel = () => window.VIZIO?.deviceModel;
  const getUUID = () => uuidv4();
  const getDeviceWidth = () => window.innerWidth;
  const getDeviceHeight = () => window.innerHeight;

  return {
    deviceId,
    ifa,
    ifaType,
    lmt,
    isCCEnabled,
    isTTSEnabled,
    firmwareVersion,
    getUserAgent,
    getDeviceModel,
    getUUID,
    getDeviceHeight,
    getDeviceWidth,
    registerCallback,
  };
};

export default useDeviceInfo;
