/* eslint-disable default-case */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PAGE_LAYOUT } from '../config/const.config';
import MainLayout from '../layout/Main';
import Loading from '../components/common/Loading';
import SideMenu from '../components/common/SideMenu';
import HorizontalList from '../components/pages/HorizontalList';
import Grid from '../components/pages/Grid';
import BgPlayer from '../components/common/BgPlayer';
import { getMenuDetails } from '../services/channelData.service';

const Home = ({ menuData, activePage, activePageLayout, handlePageChange }) => {
  const showVideo =
    activePageLayout.bgVideo !== '' &&
    activePageLayout.layout === PAGE_LAYOUT.RAIL;

  const [subMenuData, setSubMenuData] = useState([]);
  const [videosData, setVideosData] = useState({});
  const [activeSubPage, setActiveSubPage] = useState('');
  const [pageLoaded, setPageLoaded] = useState(false);
  const [subPageLoaded, setSubPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(false);
    getMenuDetails(activePage)
      .then(async (res) => {
        const menu = [];
        const menuPlaylists = {};

        res.content.playlists.forEach((m) => {
          if (m.child_playlists) {
            menu.push({
              id: m._id,
              title: m.title,
            });

            menuPlaylists[m._id] = {
              hasChildPlaylist: true,
              childPlaylists: [],
            };

            m.child_playlists.forEach((cp) => {
              if (Number(cp.videos_count) > 0) {
                menuPlaylists[m._id].childPlaylists.push({
                  id: cp._id,
                  title: cp.title,
                  videosCount: Number(cp.videos_count),
                  type: cp.program_type,
                });
              }
            });
          } else {
            if (Number(m.videos_count) > 0) {
              menu.push({
                id: m._id,
                title: m.title,
              });

              menuPlaylists[m._id] = {
                hasChildPlaylist: false,
                videosCount: Number(m.videos_count),
                type: m.program_type,
              };
            }
          }
        });

        setSubMenuData(menu);
        console.log(menuPlaylists);
        setVideosData(menuPlaylists);
        setActiveSubPage(menu[0].id.toString());
        setPageLoaded(true);
        setSubPageLoaded(true);
      })
      .catch(() => { });
  }, [activePage]);

  const handleSubPageChange = (page) => {
    setSubPageLoaded(false);
    setActiveSubPage(page.toString());
    setTimeout(() => setSubPageLoaded(true), 250);
  };

  return (
    <>
      {pageLoaded && showVideo && (
        <div className="live-video-container">
          <BgPlayer id="bg-player" source={activePageLayout.bgVideo} />
          <div className="bg-player-overlay-opacity" />
        </div>
      )}
      <MainLayout
        menuData={menuData}
        activePage={activePage}
        handlePageChange={handlePageChange}
        activePageLayoutType={activePageLayout.layout}
      >
        <div
          className={
            showVideo
              ? 'page-container has-show-bg-video'
              : 'page-container main-no-video-show'
          }
          id="page-container-main"
        >
          {!pageLoaded && <Loading showVideo={showVideo} />}
          {pageLoaded && (
            <>
              {activePageLayout.layout === PAGE_LAYOUT.GRID && (
                <>
                  <div className="side-menu">
                    <SideMenu
                      subMenuData={subMenuData}
                      activeSubPage={activeSubPage}
                      handleSubPageChange={handleSubPageChange}
                    />
                  </div>

                  {!subPageLoaded && <Loading />}

                  {subPageLoaded && (
                    <div className="page-content" id="page-content">
                      {!videosData[Number(activeSubPage)].hasChildPlaylist && (
                        <Grid
                          id={Number(activeSubPage)}
                          containerId={`grid-${activeSubPage}`}
                          videosCount={
                            videosData[Number(activeSubPage)].videosCount
                          }
                          type={videosData[Number(activeSubPage)].type}
                          activePage={activePage}
                          activeSubPage={activeSubPage}
                          keyUpElement=".top-navigation .prj-element.active"
                          keyDownElement={`grid-${Number(activeSubPage) + 1}`}
                        />
                      )}
                      {videosData[Number(activeSubPage)].hasChildPlaylist && (
                        <>
                          {videosData[Number(activeSubPage)].childPlaylists.map(
                            (cpd, idx) => (
                              <HorizontalList
                                id={cpd.id}
                                title={cpd.title}
                                containerId={`hl-${cpd.id}`}
                                videosCount={videosData[cpd.id].videosCount}
                                type={videosData[cpd.id].type}
                                activePage={activePage}
                                activeSubPage={`${cpd.id}`}
                                key={`hl${cpd.id}`}
                                keyUpElement={
                                  idx === 0
                                    ? '.top-navigation .prj-element.active'
                                    : `#hl-${videosData[Number(activeSubPage)]
                                      .childPlaylists[idx - 1].id
                                    } .prj-element`
                                }
                                keyDownElement={
                                  idx + 1 ===
                                    videosData[Number(activeSubPage)]
                                      .childPlaylists.length
                                    ? ''
                                    : `#hl-${videosData[Number(activeSubPage)]
                                      .childPlaylists[idx + 1].id
                                    } .prj-element`
                                }
                              />
                            )
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

              {activePageLayout.layout === PAGE_LAYOUT.RAIL && (
                <div
                  className={`page-content ${showVideo ? 'has-bg-video' : ''}`}
                  id="page-content"
                >
                  {subMenuData.map((smd, idx) => (
                    <HorizontalList
                      id={smd.id}
                      title={smd.title}
                      containerId={`hl-${smd.id}`}
                      videosCount={videosData[smd.id].videosCount}
                      type={videosData[smd.id].type}
                      activePage={activePage}
                      activeSubPage={`${smd.id}`}
                      key={`hl${smd.id}`}
                      keyUpElement={
                        idx === 0
                          ? '.top-navigation .prj-element.active'
                          : `#hl-${subMenuData[idx - 1].id} .prj-element`
                      }
                      keyDownElement={
                        idx + 1 === subMenuData.length
                          ? ''
                          : `#hl-${subMenuData[idx + 1].id} .prj-element`
                      }
                    />
                  ))}
                  &nbsp;
                </div>
              )}
            </>
          )}
        </div>
      </MainLayout>
    </>
  );
};

Home.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired,
  activePageLayout: PropTypes.shape({
    layout: PropTypes.string,
    bgVideo: PropTypes.string,
  }).isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default Home;
